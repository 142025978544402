import React from "react"
import LayOut from "../components/layout"
import BlogHeader from "../components/blog/herobanner/index"
import BlogContent from "../components/blog/blogContent"
import BlogsSEO from "../components/blog/seo"
import { useStaticQuery, graphql } from "gatsby"

const Blog = () => {
  const { allSanityBlogs } = useStaticQuery(graphql`
    query blogPostsQuery {
      allSanityBlogs(sort: { fields: publishdate, order: DESC }) {
        edges {
          node {
            _key
            title
            publishdate(formatString: "MMM DD, YYYY")
            featureImage {
              asset {
                url
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
            excerpt
            link {
              internallink
              linktext
              newwindow
              url
            }
            sanityInternal
            slug {
              current
            }
            authorName
            authorImage {
              asset {
                url
                gatsbyImageData(
                  width: 60
                  layout: CONSTRAINED
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
            categories
          }
        }
        totalCount
      }
    }
  `)

  return (
    <LayOut>
      <BlogsSEO />
      <div>
        <BlogHeader />
        <BlogContent blogData={allSanityBlogs} isBlog />
      </div>
    </LayOut>
  )
}

export default Blog
